<template>
  <img alt="Regit Logo" width="150" v-lazy="imgUrl" />
</template>

<script>
  export default {
    name: 'RegitLogo',
    data () {
      return {
        imgUrl: require('@/assets/img/affiliates/Regit-2.png')
      }
    }
  }
</script>

<style scoped>

</style>